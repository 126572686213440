import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import classNames from 'classnames';
import styles from './Notice.module.scss';

const Notice = ({ href, title, modifier }) => {
    const noticeClass = classNames(styles['Notice'], {
        [styles['Notice--NewsPage']]: modifier === 'NewsPage',
    });

    return (
        <a href={href} className={noticeClass}>
            <span className={styles['Notice__Label']}>
                {i18n.t('notice.label')}
            </span>
            <h2 className={styles['Notice__Text']}>{title}</h2>
        </a>
    );
};

Notice.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
};

Notice.defaultProps = {
    href: '',
    title: '',
};

export default Notice;
