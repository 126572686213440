import React from 'react';
import PropTypes from 'prop-types';

import styles from './HeroFrontPage.module.scss';

const HeroFrontPage = ({ title, text, image }) => {
    let style = {};

    if (image?.file) {
        style.backgroundImage = `url(${image.file})`;
    }

    return (
        <div className={styles['HeroFrontPage']}>
            <div className={styles['HeroFrontPage__Container']} style={style}>
                <div
                    className={styles['HeroFrontPage__MobileContainer']}
                    style={style}
                />
                <div className={styles['HeroFrontPage__ContainerInner']}>
                    <h1 className={styles['HeroFrontPage__Title']}>{title}</h1>
                    <div className={styles['HeroFrontPage__Text']}>{text}</div>
                </div>
            </div>
        </div>
    );
};

HeroFrontPage.propTypes = {
    title: PropTypes.string.isRequired,
};

HeroFrontPage.defaultProps = {
    title: '',
};

export default HeroFrontPage;
